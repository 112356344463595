Vue.component('staticViewPage', {
    computed: {
        currentPageComponent:function(){
            if(['sso','catalogo','ecp',"entregas","pedidos"].indexOf(this.$route.params.staticName.toLowerCase())!=-1)
                return `service${this.$route.params.staticName.toLowerCase()}`;
            if(['etica','vmv','beneficios'].indexOf(this.$route.params.staticName.toLowerCase())!=-1)
                return `about${this.$route.params.staticName.toLowerCase()}`;
            if(['clientes','proveedores','donde'].indexOf(this.$route.params.staticName.toLowerCase())!=-1)
                return `contact${this.$route.params.staticName.toLowerCase()}`;
            if(['principios','social','ambiental','economico'].indexOf(this.$route.params.staticName.toLowerCase())!=-1)
                return `rse${this.$route.params.staticName.toLowerCase()}`;
            return 'staticunknow'
        }
    },
    template: `<div class="items-view">
                  <headerSectionComponent :renderMenu="false"></headerSectionComponent>
                   <nav>
                      <ul>
                            <li><router-link to="/home">INICIO</router-link></li>
                            <li><router-link to="/company/sso">SERVICIOS</router-link></li>
                            <li><router-link to="/company/vmv">SOBRE NOSOTROS</router-link></li>
                            <li><router-link to="/company/clientes" >CONTACTO</router-link></li>
                            <li><router-link to="/company/principios">RSE</router-link></li>
                      </ul>
                </nav>
                  <div class="section white">
                      <div class="container">
                          <template v-if="$store.getters.getAppState=='ready'">
                                <div class="row static_company">
                                   <component v-bind:is="currentPageComponent"></component>
                              </div>
                          </template>
                          <template v-if="$store.getters.getAppState=='onload'">     
                                <div class="container center section white" style="min-height: 60%;" >
                                       <h3>{{tr('Page loading please wait')}}</h3>
                                </div>
                          </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`,
});
